import { FC } from 'react';

import { useDrawer } from '@lib/DrawerContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Tenant } from '@lib/tenants/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Drawer as MDrawer } from '@mui/material';

import { DrawerMenu } from './drawer-menu/DrawerMenu';
import { DrawerHeader } from './DrawerHeader';

export type DrawerProps = Record<string, never>;

export const Drawer: FC<DrawerProps> = () => {
  const { tenant } = useTenantProps();
  const { isOpen, hideDrawer } = useDrawer();
  const dynamicStyles = styles({ specifics: tenant.theme?.specifics });

  return (
    <MDrawer
      anchor="left"
      sx={dynamicStyles.getValue('root')}
      open={isOpen}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onClose={hideDrawer}
    >
      <DrawerHeader />
      <DrawerMenu />
    </MDrawer>
  );
};

interface StyleProps {
  specifics: Tenant['theme']['specifics'];
}

const styles = ({ specifics }: StyleProps) =>
  new SxStyles({
    root: {
      '& .MuiDrawer-paper': {
        background: specifics?.drawer?.background ?? 'initial',
        backgroundColor: specifics?.drawer?.backgroundColor ?? 'light.main',
        color: specifics?.drawer?.color ?? 'initial',
        width: {
          xs: '100%',
          sm: 400,
        },
        boxSizing: 'border-box',
      },
    },
  });
